@import url(../default.css);


body {
    background-color: var(--BACKGROUND_COLOR_DARK);
}

.card-login {
    border: none;

}
.card-login .card-header {
    background-color: var(--LOGIN_PRIMARY_COLOR);
    border-bottom: 10px solid var(--LOGIN_PRIMARY_DARK_COLOR);
    padding: 2.5rem;
}

.card-login .card-body {
    background-color: #eaeaec;
}

.form-control {
    background-color: #fff;
    color: #212529;
    border: 1px solid #eaeaea;
}

.form-control:focus {
    background-color: #fff;
    color: #212529;
    border: 1px solid #eaeaea;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.login-button {
    background: var(--LOGIN_PRIMARY_COLOR);
    border-color: var(--LOGIN_PRIMARY_COLOR);
    color: #fff;
    font-weight: 300;
    text-transform: uppercase;
    font-family: 'Open Sans',sans-serif;
    padding: 12px;
    border-radius: 6px;
    border: 0;
}

.login-button:hover {
    background: var(--LOGIN_PRIMARY_COLOR);
    border-color: var(--LOGIN_PRIMARY_COLOR);
}
.login-button:focus {
    background: var(--LOGIN_PRIMARY_COLOR);
    border-color: var(--LOGIN_PRIMARY_COLOR);
}

a {
    text-decoration: none;
}
  