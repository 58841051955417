
:root {
    --BACKGROUND_COLOR_LIGHT: #FFF;
    --BACKGROUND_COLOR_DARK: #32323A;
    --LOGIN_PRIMARY_COLOR: rgb(201, 145, 85);
    --LOGIN_PRIMARY_DARK_COLOR: rgb(168, 121, 70);

    --PRIMARY_BUTTON: rgba(33, 150, 243, 1.0);
    --PRIMARY_DARK_BUTTON: rgba(23, 105, 170, 1.0);
}

.btn {
    padding: 0.25rem 0.5rem;
}

.btn-primary {
    color: #fff;
    background-color: var(--PRIMARY_BUTTON);
    border-color: var(--PRIMARY_BUTTON);
}
.btn-primary:hover {
    background-color: var(--PRIMARY_DARK_BUTTON);
    border-color: var(--PRIMARY_DARK_BUTTON);

}

.alert {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
}

.alert .btn-close {
    padding: 4px;
    height: 0.2rem;
    width: 0.2rem;
    border: 0;
    margin: 5px 5px;
}

.alert .btn-close:focus {
    border: 0;
    box-shadow: none;
}


.title {
    font-size: 25px;
    font-weight: bold;
    color: #525252;
    margin-bottom: 30px;
}

textarea {
    max-height: 250px;
}

.table-images {
  overflow-x: auto;
  display: flex;
  gap: 1rem;
}

.table-images img {
  width: 128px;
  height: 64px;
  object-fit: contain;
  margin-bottom: 1rem;
}

table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }
  
  table caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
  }
  
  table tr {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: .35em;
  }
  
  table th,
  table td {
    padding: .625em;
    text-align: center;
  }
  
  table th {
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
  }
  
  @media screen and (max-width: 600px) {
    table {
      border: 0;
    }
  
    table caption {
      font-size: 1.3em;
    }
    
    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    
    table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: .625em;
    }
    
    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: .8em;
      text-align: right;
    }
    
    table td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
    
    table td:last-child {
      border-bottom: 0;
    }
  }
  
  
  
  
  
  
  
  
  
  